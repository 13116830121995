module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"ZenSim","short_name":"ZenSim","start_url":"/","background_color":"#157de9","theme_color":"#daed51","display":"minimal-ui","icon":"src/images/favicons/android-chrome-512x512.png","icons":[{"src":"/favicons/favicon-16x16.png","sizes":"16x16","type":"image/png"},{"src":"/favicons/favicon-32x32.png","sizes":"32x32","type":"image/png"},{"src":"/favicons/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/favicons/android-chrome-512x512.png","sizes":"512x512","type":"image/png"},{"src":"/favicons/apple-touch-icon.png","sizes":"180x180","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7a3751d32c54c1a79d7398fad3e320ca"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-ZW7LLJRL79"],"gtagConfig":{"cookie_flags":"SameSite=None;Secure","cookie_domain":"auto","anonymize_ip":false}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TTHXC4D6","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{}},"enableWebVitalsTracking":true,"routeChangeEventName":"gatsby-route-change","selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
