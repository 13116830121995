import React from 'react'
import { twMerge } from 'tailwind-merge'

interface Props {
    className?: string
    children: React.ReactNode
}

const Container: React.FC<Props> = ({ children, className }: Props) => {
    return <div className={twMerge('container mx-auto xl:flex xl:items-center', className)}>{children}</div>
}

export default Container
