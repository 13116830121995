import { ApolloClient, InMemoryCache, HttpLink, from } from '@apollo/client'

import { RetryLink } from '@apollo/client/link/retry'

const retry = new RetryLink()
const httpLink = new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_URL })

export const client = new ApolloClient({
    cache: new InMemoryCache(),
    connectToDevTools: true,
    link: from([retry, httpLink])
})
