import React from 'react'
import { ErrorBoundary as SentryErrorBoundary } from '@sentry/gatsby'
import warning from '../images/icon-warning.png'
import Container from './container'

interface Props {
    children: React.ReactNode
}

const ErrorBoundary: React.FC<Props> = ({ children }) => {
    const Fallback = (): React.ReactElement => {
        return (
            <Container>
                <div className='mx-4 my-4 flex items-center rounded-xl bg-white p-4 lg:mx-0 lg:w-full'>
                    <img src={warning} alt='An error occurred' className='mr-4 w-[25px] lg:w-[30px]' />
                    <div className='text-sm lg:text-base'>
                        Uh oh! Something went wrong. <br className='hidden lg:inline' />
                        Please try again, and if it's still not playing ball,{' '}
                        <a href='/contact/' className='whitespace-nowrap underline hover:no-underline' target='_blank'>
                            contact us
                        </a>
                        .
                    </div>
                </div>
            </Container>
        )
    }

    return <SentryErrorBoundary fallback={Fallback}>{children}</SentryErrorBoundary>
}

export default ErrorBoundary
